.LoadingIndicator {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition-delay: 0;
  transform-origin: left center;
  transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator.active {
  opacity: 1;
  transition-delay: 333ms;
  animation: LoadingIndicator-animation 10s ease-out;
  animation-fill-mode: forwards;
}

@keyframes LoadingIndicator-animation {
  0% {
    transform: scaleX(0);
  }
  10% {
    transform: scaleX(0.3);
  }
  50% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}